import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchPrograms, FETCH_PROGRAM_WITH_ID_SUCCESS } from "../../redux/programActions";
import { fetchPrincipalWithId } from "../../redux/principalActions";

import '../pages.scss';
import { IoMdArrowDropright } from "react-icons/io";
import Image from 'react-bootstrap/Image';
import { IoIosSearch } from "react-icons/io";
import { IoMdArrowBack } from "react-icons/io";



function ProgramPage() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { program_list, loading, error } = useSelector((state) => state.program);
    const { principal } = useSelector((state) => state.principal);

    const [searchTerm, setSearchTerm] = useState("");
    const [isSearchVisible, setSearchVisible] = useState(false); // State to manage search visibility

    useEffect(() => {
        dispatch(fetchPrograms(id));
        if (!(principal && principal.principalId)) {
            dispatch(fetchPrincipalWithId(id))
        }
    }, [dispatch, id, principal]);

    const handleClick = async (programId, name) => {
        try {
            dispatch({
                type: FETCH_PROGRAM_WITH_ID_SUCCESS,
                payload: {
                    programName: name,
                    principalId: id,
                    programId: programId
                }
            });

            navigate('/program/' + programId);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredPrograms = program_list ? program_list["rows"].filter(item =>
        item["programName"].toLowerCase().includes(searchTerm.toLowerCase())
    ) : [];

    const toggleSearchVisibility = () => {
        setSearchVisible(prev => !prev);
        if (isSearchVisible) {
            setSearchTerm(""); // Clear search term when hiding
        }
    };


    return (
        <div>
            {loading && (
                <div className="loading-overlay">
                    <Spinner animation="border" variant="primary" />
                </div>
            )}

            {program_list && principal && principal.principalId &&
                (
                    <div>
                        <div className="navbar-header">
                            <IoMdArrowBack onClick={() => navigate("/principal")} />

                            <div>
                                {!isSearchVisible && (<>Programs
                                    <div className="subheading">{principal.name}</div></>
                                )}

                            </div>
                            <div className="navbar-search">
                                <input
                                    className={isSearchVisible ? "active" : ""}
                                    type="text"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    placeholder="Search"
                                />
                                <IoIosSearch
                                    onClick={toggleSearchVisibility} // Toggle search visibility on icon click
                                />
                            </div>

                        </div>
                        <div className="navbar-page">
                            {filteredPrograms.map((item, index) => (
                                <div key={index} className="list-item" onClick={() => handleClick(item["programId"], item["displayName"])}>
                                    <div>
                                        {item["logo"] ? <Image src={item["logo"]} alt={item["programName"]} /> : null}
                                        <div>{item["programName"]}</div>
                                    </div>
                                    <IoMdArrowDropright />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
        </div>
    );
}

export default ProgramPage;
