import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import PrincipalPage from './pages/listings/PrincipalPage';
import ProgramPage from './pages/listings/ProgramPage';
import TenantPage from './pages/listings/TenantPage';
import VpaPage from './pages/listings/VpaPage';
import TenantDetailPage from './pages/detail/TenantDetailPage';
import TenantEditPage from './pages/detail/TenantEditPage';
import LoginPage from './pages/auth/LoginPage';
import QrScanner from './pages/scan/QrScanPage';
import './App.scss';
import './components/card/card.scss';
import { jwtDecode } from "jwt-decode";

// Check if the token is expired
const isTokenExpired = (token) => {
  if (!token) return true;

  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000; // Time in seconds

  // Check if the token's expiration time has passed
  return decoded.exp < currentTime;
};

// Mock authentication function
const isAuthenticated = () => {
  // This is just a basic example. You can check localStorage or use an authentication API.
  return !!localStorage.getItem('accessToken');
};

function PrivateRoute({ element, ...rest }) {
  const token = localStorage.getItem('accessToken');

  if (isTokenExpired(token)) {
    localStorage.removeItem('accessToken');
    return <Navigate to="/" />; // Redirect to login if token is expired
  }

  return  React.cloneElement(element, { ...rest })
  ;
}



function App() {
  const [auth, setAuth] = useState(isAuthenticated());

  const { program } = useSelector((state) => state.program);


  // Set up a listener for auth changes, like from login/logout actions
  useEffect(() => {
    const authToken = localStorage.getItem('accessToken');
    setAuth(!!authToken);
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={auth ? <Navigate to="/principal" /> : <LoginPage setAuth={setAuth} />} />
          <Route path="/principal" element={<PrivateRoute element={<PrincipalPage setAuth={setAuth} />} />} />
          <Route path="/principal/:id" element={<PrivateRoute element={<ProgramPage />} />} />
          <Route path="/program/:id" element={<PrivateRoute element={<TenantPage />} program={program} />} />
          <Route path="/tenant/:id" element={<PrivateRoute element={<TenantDetailPage />} />} />
          <Route path="/tenant/edit/:id" element={<PrivateRoute element={<TenantEditPage />} />} />
          <Route path="/tenant/vpa/:id" element={<PrivateRoute element={<VpaPage />} />} />
          <Route path="/tenant/vpa/add/:id" element={<PrivateRoute element={<QrScanner />} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;



