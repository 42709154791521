import {
    FETCH_PRINCIPAL_REQUEST,
    FETCH_PRINCIPAL_SUCCESS,
    FETCH_PRINCIPAL_FAILURE,
    FETCH_PRINCIPAL_WITH_ID_SUCCESS,
    FETCH_PRINCIPAL_WITH_ID_FAILURE,
    FETCH_PRINCIPAL_WITH_ID_REQUEST,
    CLEAR_STORE
} from './principalActions';

const initialState = {
    loading: true,
    principal_list: null,
    error: null,
    principal : null
};

const voucherReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PRINCIPAL_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_PRINCIPAL_SUCCESS:
            return {
                ...state,
                loading: false,
                principal_list: action.payload,
            };
        case FETCH_PRINCIPAL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

            case FETCH_PRINCIPAL_WITH_ID_REQUEST:
                return {
                    ...state,
                    loading: true,
                    error: null,
                };
            case FETCH_PRINCIPAL_WITH_ID_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    principal: action.payload,
                };
            case FETCH_PRINCIPAL_WITH_ID_FAILURE:
                return {
                    ...state,
                    loading: false,
                    error: action.error,
                };
        case CLEAR_STORE:
            return initialState; // Reset the store to the initial state
        default:
            return state;
    }
};

export default voucherReducer;