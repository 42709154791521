import { createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import principalReducer from './principalReducer';
import programReducer from './programReducer'
import tenantReducer from './tenantReducer'
import vpaReducer  from './vpaReducer';
// import logger from 'redux-logger'


const rootReducer = combineReducers({
    principal: principalReducer,
    program : programReducer,
    tenant: tenantReducer,
    vpa: vpaReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;