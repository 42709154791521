import React, { useEffect } from "react";
import { Button, Spinner, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchTenant } from "../../redux/tenantActions";
import '../pages.scss'
import { IoMdArrowBack } from "react-icons/io";
import { useParams } from 'react-router-dom';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import PlaceholderImage from "../../assests/placeholder.jpg";




function TenantDetailPage() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const { tenant_details, loading, error } = useSelector((state) => state.tenant);


    useEffect(() => {
        dispatch(fetchTenant(id))
    }, []);



    const handleClick = async () => {
        try {
            navigate('/tenant/edit/' + id)
        } catch (error) {
        }
    };


    const handleVpaClick = (tenandId) => {
        try {
            navigate(`/tenant/vpa/${tenandId}`)
        } catch (error) {
        }
    };



    return (
        <div>
            {loading && (
                <div className="loading-overlay" >
                    <Spinner animation="border" variant="primary" />
                </div>
            )}

            {
                tenant_details && (
                    <div>

                        <div className='tenant-navbar-header'>
                            <IoMdArrowBack onClick={() => navigate("/program/" + tenant_details.programId)} />
                            {tenant_details.tenantName}
                            <Button onClick={handleClick}>Edit</Button>
                        </div>

                        <div className="tenant-page">
                            <Image src={tenant_details["storeImage"] ? tenant_details["storeImage"] + `?t=${new Date().getTime()}` : PlaceholderImage} className="tenant" />

                            <div className="add-vpa-section">
                                {tenant_details["vpa_count"]} VPAs
                                <Button onClick={() => handleVpaClick(tenant_details["tenantId"])}>Add VPA</Button>
                            </div>

                            
                            <div className="store-id-logo-container">
                                <div className="title">
                                    Store Id: {tenant_details["tenantId"]}
                                </div>
                                <Image src={tenant_details["storeLogo"] ? tenant_details["storeLogo"] + `?t=${new Date().getTime()}` : PlaceholderImage} className="tenant-logo" />
                            </div>

                            <div className="tenant-contact-details">
                                <Form>
                                    <div className="disable">
                                        <FloatingLabel className="disabled-label" controlId="name" label="Contact Person Name">
                                            <Form.Control disabled className="disabled"  placeholder="Name" value={tenant_details["storeCoordinator"]} />
                                        </FloatingLabel>
                                    </div>

                                    <div className="disable">
                                        <FloatingLabel controlId="number" label="Contact Person Number">
                                            <Form.Control disabled className="disabled"  placeholder="Phone Number" value={tenant_details["phone"]} />
                                        </FloatingLabel>
                                    </div>

                                    <div className="disable">
                                        <FloatingLabel controlId="address" label="Address">
                                            <Form.Control disabled className="disabled"  placeholder="Address Line 1" value={tenant_details["address"]} />
                                        </FloatingLabel>
                                    </div>

                                    <div className="disable">
                                        <FloatingLabel controlId="state" label="State">
                                            <Form.Control disabled className="disabled"  placeholder="State" value={tenant_details["state"]} />
                                        </FloatingLabel>
                                    </div>

                                    <div className="disable">
                                        <FloatingLabel controlId="city" label="City">
                                            <Form.Control disabled className="disabled"  placeholder="City" value={tenant_details["city"]} />
                                        </FloatingLabel>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                )}

        </div>
    );
}

export default TenantDetailPage;
