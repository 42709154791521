import axios from 'axios';

export const FETCH_TENANT_REQUEST = 'FETCH_TENANT_REQUEST';
export const FETCH_TENANT_SUCCESS = 'FETCH_TENANT_SUCCESS';
export const FETCH_TENANT_FAILURE = 'FETCH_TENANT_FAILURE';

export const FETCH_TENANT_DETAIL_REQUEST = "FETCH_TENANT_DETAIL_REQUEST"
export const FETCH_TENANT_DETAIL_SUCCESS = "FETCH_TENANT_DETAIL_SUCCESS"
export const FETCH_TENANT_DETAIL_FAILURE = "FETCH_TENANT_DETAIL_FAILURE"

export const UPDATE_TENANT_DETAIL_REQUEST = "UPDATE_TENANT_DETAIL_REQUEST"
export const UPDATE_TENANT_DETAIL_SUCCESS = "UPDATE_TENANT_DETAIL_SUCCESS"
export const UPDATE_TENANT_DETAIL_FAILURE = "UPDATE_TENANT_DETAIL_FAILURE"

export const CLEAR_STORE = "CLEAR_STORE"

export const clearStoreAction = () => ({
    type: 'CLEAR_STORE',
});

export const fetchTenants = (programId) => async (dispatch) => {
    dispatch({ type: FETCH_TENANT_REQUEST });
    try {
        const token = localStorage.getItem('accessToken')
        const userDetails = JSON.parse(localStorage.getItem('userDetails'))


        const response = await axios.request({
            baseURL: window.env.API_URL + `/v1/programs/tenant/${userDetails.roleResourceId}?programId=${programId}`, // Base URL for your API
            timeout: 10000000, // Timeout in milliseconds
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,

            },
            method: 'GET'
        })

        dispatch({ type: FETCH_TENANT_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_TENANT_FAILURE, error: error.message });
    }
};

export const fetchTenant = (tenantId) => async (dispatch) => {
    dispatch({ type: FETCH_TENANT_DETAIL_REQUEST});
    try {
        const token = localStorage.getItem('accessToken')
        const userDetails = JSON.parse(localStorage.getItem('userDetails'))
        const response = await axios.request({
            baseURL: window.env.API_URL + `/v1/programs/tenant/${userDetails.roleResourceId}/${tenantId}`, // Base URL for your API
            timeout: 10000000, 
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,

            },
            method: 'GET'
        })

        dispatch({ type: FETCH_TENANT_DETAIL_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_TENANT_DETAIL_FAILURE, error: error.message });
    }
};



export const updateTenant = (storeCoordinator, phone, address, image, logo, tenantId, latitude, longitude, state, city) => async (dispatch) => {
    // dispatch({ type: UPDATE_TENANT_DETAIL_REQUEST});
    try {
        const token = localStorage.getItem('accessToken')
        const userDetails = JSON.parse(localStorage.getItem('userDetails'))
        const formData = new FormData();
        formData.append('storeCoordinator', storeCoordinator);
        formData.append('phone', phone);
        formData.append('address', address);
        formData.append('latitude', latitude)
        formData.append('longitude', longitude)
        formData.append('state', state)
        formData.append('city', city)

        // Append the image file if it exists
        if (image) {
            formData.append('storeImage.file', image);
        }

        // Append the logo file if it exists
        if (logo) {
            formData.append('storeLogo.file', logo); // Ensure that the field name matches the one expected by your backend
        }
        const response = await axios.request({
            baseURL: window.env.API_URL  + `/v1/programs/tenant/${userDetails.roleResourceId}/${tenantId}`, 
            timeout: 10000000, 
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            method: 'PUT',
            data: formData
        })

        if (response.status == 200){
            return Promise.resolve()
        }
        else{
            return Promise.reject()
        }
        // dispatch({ type: UPDATE_TENANT_DETAIL_SUCCESS, payload: response.data });
    } catch (error) {
        // dispatch({ type: UPDATE_TENANT_DETAIL_FAILURE, error: error.message });
        return Promise.reject()
    }
};


