import axios from 'axios';

export const FETCH_PRINCIPAL_REQUEST = 'FETCH_PRINCIPAL_REQUEST';
export const FETCH_PRINCIPAL_SUCCESS = 'FETCH_PRINCIPAL_SUCCESS';
export const FETCH_PRINCIPAL_FAILURE = 'FETCH_PRINCIPAL_FAILURE';

export const FETCH_PRINCIPAL_WITH_ID_REQUEST = 'FETCH_PRINCIPAL_WITH_ID_REQUEST';
export const FETCH_PRINCIPAL_WITH_ID_SUCCESS = 'FETCH_PRINCIPAL_WITH_ID_SUCCESS';
export const FETCH_PRINCIPAL_WITH_ID_FAILURE = 'FETCH_PRINCIPAL_WITH_ID_FAILURE';



export const CLEAR_STORE = "CLEAR_STORE"

export const clearStoreAction = () => ({
    type: 'CLEAR_STORE',
});

export const fetchPrincipals = () => async (dispatch) => {
    dispatch({ type: FETCH_PRINCIPAL_REQUEST });
    try {
        const token = localStorage.getItem('accessToken')
        const response = await axios.request({
            baseURL: window.env.API_URL + '/v1/programs/principals/?page=1&limit=1000',
            timeout: 10000000, 
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,

            },
            method: 'GET'
        })

        dispatch({ type: FETCH_PRINCIPAL_SUCCESS, payload: response.data });
    } catch (error) {
        console.error(error)
        dispatch({ type: FETCH_PRINCIPAL_FAILURE, error: error.message });
    }
};



export const fetchPrincipalWithId = (principal_id) => async (dispatch) => {
    dispatch({ type: FETCH_PRINCIPAL_WITH_ID_REQUEST });

    try {
        const token = localStorage.getItem('accessToken')
        const response = await axios.request({
            baseURL: window.env.API_URL + `/v1/programs/principals/${principal_id}`,
            timeout: 10000000, 
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,

            },
            method: 'GET'
        })

        dispatch({ type: FETCH_PRINCIPAL_WITH_ID_SUCCESS, payload: response.data.principal });
    } catch (error) {
        console.error(error)
        dispatch({ type: FETCH_PRINCIPAL_WITH_ID_FAILURE, error: error.message });
    }
};

